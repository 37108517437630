<template>
<div>
  <!-- OTC hero tokens popup -->
  <div class='fixed inset-0 bg-black bg-opacity-75 z-50 flex items-center justify-center' v-if="showOTCHeroTokenPopup" @click="showOTCHeroTokenPopup = false">
    <div class='bg-cdlcream z-50 max-w-full md:max-w-2xl p-10 rounded-3xl'><p>Fractions can be bought OTC by sending ETH to following ERC-20 address:
<span class='break-all'>0x4996eEaEC1928f8A63C188080525e717eC5c93c4</span><br><br>

The price is 0.00003 ETH per $HEROES Token<br>
As soon as the transaction is confirmed on the blockchain the tokens will be sent to your wallet.<br><br>

DON'T SEND FUNDS FROM AN EXCHANGE WALLET!!! ONLY USE YOUR PERSONAL WALLET!!!<br><br>

$HEROES tokens are being traded on the <a class='underline' href="https://fractional.art/vaults/0x619abba3218226040bb97a9e4cd6f2ad84a33e6e" target="_blank">open market</a><br>
</p></div>
  </div>
  <BaseContainer class="relative z-30 pb-40" id="heroes">
    <h2 class="text-cdlpink pb-4 pt-10">Heroes</h2>
    <p class="pb-10">
      Seek out 10 highly regarded characters. What will be their story? Time will tell whether to keep or sell. It goes up and down like heaven and hell.
    </p>

    <div class="md:grid md:grid-cols-2">
      <div class='pt-4 pb-6'>
        <h3 class='text-cdlpink'>Leader [fractionalized]</h3>
        <p class='md:pr-8'>
          The highest rank in the collection Heroes Of Ukraine (Mr.Zelensky) is also ONLY one NFT to be fractionalized. The remaining 10 NFT (Ukraine's political, social and sport life icons) will be available on the OpenSea platform right after.
        </p>
      </div>
      <div
        class="
          p-4
          bg-cdlcream
          rounded-2xl
          h-full
          flex flex-col
          items-start
          justify-between
        "
      >
        <div>
          <img :src="getImgSrc(zelenskyj.img)" v-if="zelenskyj.img" />
          <div class="pt-4">
            <h3 class="text-2xl text-cdlpink">{{ zelenskyj.title }}</h3>
            <span class="text-xl text-cdlblack-light"
              >for {{ zelenskyj.area }}</span
            >
            <p class="pt-4">{{ zelenskyj.description }}</p>
          </div>
        </div>
        <BaseButton class="mt-6" ukraine :url="zelenskyj.url" :event="`ukraine_get_${zelenskyj.id}_click`">{{
          zelenskyj.cta
        }}</BaseButton>
        <BaseButton class="mt-4 ml-4 underline text-gray-400" naked :event="`ukraine_get_${zelenskyj.id}_otc_click`" @click="showOTCHeroTokenPopup = true">{{
          zelenskyj.ctaOTC
        }}</BaseButton>
      </div>
    </div>

    <div class='pt-20'>
      <h3 class='text-cdlpink'>Others [auctioned]</h3>
      <p>
        Right after the first and only fractionalized NFT from the "Heroes Of Ukraine" collection will be sold, the remaining 10 NFTs from the collection will be available at Opensea platform for sale. 
      </p>

      <div class="mt-6 grid gap-4 md:grid-cols-2 xl:grid-cols-3">
        <div class="flex flex-col w-full" v-for="nft in nfts" :key="nft.id">
          <div
            class="
              p-4
              bg-cdlcream
              rounded-2xl
              h-full
              flex flex-col
              items-start
              justify-between
            "
          >
            <div class='relative'>
              <img :src="getImgSrc(nft.img)" v-if="nft.img" />

              <!-- missing image -->
              <div
                class="nft bg-gray-300 flex items-center justify-center"
                v-else
              >
                <span class="block text-2xl text-gray-400">in the works</span>
              </div>

              <!-- image overlay -->
              <div
                class="nft absolute top-0 right-0 left-0 flex items-center justify-center"
                v-if="nft.overlay"
              >
                <span class="block text-2xl text-white">{{nft.overlay}}</span>
              </div>


              <div class="pt-4">
                <h3 class="text-2xl text-cdlpink">{{ nft.title }}</h3>
                <span class="text-xl text-cdlblack-light"
                  >for {{ nft.area }}</span
                >
                <p class="pt-4">{{ nft.description }}</p>
              </div>
            </div>
            <!-- <p class='mt-4 text-gray-400'>{{nft.cta}} coming soon</p> -->
            <BaseButton class="mt-6" ukraine :url="nft.url" :event="`ukraine_get_${nft.id}_click`">Get {{
              nft.cta
            }}</BaseButton>
          </div>
        </div>
      </div>
    </div>
  </BaseContainer>
  </div>
</template>

<script>
import mixinInitLoader from "@/mixins/initLoader"

export default {
  name: "Gallery",
  mixins: [mixinInitLoader],
  components: {},
  data() {
    return {
      showOTCHeroTokenPopup: false,
      zelenskyj: {
        id: "wolfdymyr-zelenskyj",
        img: "nft-zelenskyj.png",
        title: "Wolfdymyr Zelenskyj",
        description:
          "The key figure. Comedian who became the president of UA - first in a TV series where he played a teacher becoming president, then he made it a reality! Now you can call that coincidence. The symbol of resistance. Infamous for his night shifts in the trenches and Tik Tok videos uplifting morale by the daylight. Needs no ride.",
        area: "Ukraine",
        cta: "Support Wolfdymyr at fractional.art",
        url: "https://fractional.art/vaults/0x619abba3218226040bb97a9e4cd6f2ad84a33e6e",
        ctaOTC: 'Support Wolfdymyr OTC via HERO token'
      },
      nfts: [
        {
          id: "vitaliy-pinchko",
          img: "nft-vitaliy-pinchko.jpg",
          title: "Vitaliy Pinchko",
          description:
            "Former MP. Mayor of Kyiv. World boxing champion. Legend. Unbeatable half of the Pinchko brothers. Defended 12 titles! In the action again these days - with his brother Vladimir, defending the capital city of Kyiv. Expecting heavy blows and punches! His famous motto is \"Not a single pinch of Ukrainian soil!\"",
          area: "Kiyv",
          cta: "Vitaly",
          url: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/33285512333342024108283031194714801062523424925006989749410649456486966624257",
        },
        {
          id: "mila-canis",
          img: "nft-mila-canis.jpg",
          title: "Mila Canis",
          description:
            "The Ukrainian beauty of Hollywood. She never forgot her home country and language. She took action amid the recent events in Ukraine, raising funds for those affected. Also known to play in one of the most expensive movies of all time, “Jupiter Ascending”, a movie with a most complicated story, which no one, including its director, has ever explained!",
          area: "Zhytomyr",
          cta: "Mila",
          url: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/33285512333342024108283031194714801062523424925006989749410649453188431740929",
        },
        {
          id: "anastashiba-lena",
          img: "nft-anastashiba-lena.jpg",
          title: "Anastashiba Lena",
          description:
            "Magnificence itself, the most beautiful woman of Ukraine, the Grand Miss of UA. Her charisma can be seen only on the battlefield these days. It is hard to believe, that she changed the dress and jewellery for a camouflaged uniform and a “light” machine gun. Not that it would be so light, but you know - model must keep her weight no matter the war.",
          area: "Odesa",
          cta: "Anastashiba",
          url: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/33285512333342024108283031194714801062523424925006989749410649447690873602049",
        },
        {
          id: "canis-shmyhal",
          img: "nft-canis-shmyhal.jpg",
          title: "Canis Shmyhal",
          description:
            "Another key figure of Ukraine. Head of government, one of the main negotiators for the country. He served in multiple leading political roles in Ukraine's region Lviv, being also its governor. In 2020 he became the Prime Minister of the country. He gets straight to the point, and he says it loudly!",
          area: "Mariupol",
          cta: "Canis",
          url: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/33285512333342024108283031194714801062523424925006989749410649445491850346497",
        },
        {
          id: "milla-jovospitz",
          img: "nft-milla-jovospitz.jpg",
          title: "Milla Jovospitz",
          description:
            "Who wouldn't be aware of her? A renowned movie star, born in UA, of Ukrainian mother and Siberian father. Later she moved with her parents to Moscow. Definitely knows where her country is on the map. 5th element, Resident Evil, Hellboy, Zoolander, or The Expendables, there is a huge pile of movies she excelled in, and she is not stopping yet.",
          area: "Chernihiv",
          cta: "Milla",
          url: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/33285512333342024108283031194714801062523424925006989749410649454287943368705",
        },
        {
          id: "sharpei-bubka",
          img: "nft-sharpei-bubka.jpg",
          title: "Sharpei Bubka",
          description:
            "A legend of the sporting world. A Golden Olympic medalist. World records holder unbeatable for years. If Neil Armstrong was the first man on the Moon, Sharpei Bubka was the first man who jumped over 6 meters in pole vault discipline and survived! A Member of International Olympic Committee, true icon of his own.",
          area: "Mykolaiv",
          cta: "Sharpei",
          url: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/33285512333342024108283031194714801062523424925006989749410649455387454996481",
        },
        {
          id: "dogsana-baiul",
          img: "nft-dogsana-baiul.jpg",
          title: "Dogsana Baiul",
          description:
            "The Ukrainian swan on the ice. First golden Olympic medalist in the history of independent Ukraine. Moved to the U.S.A. in 1994. Representing Ukraine also in drinking and driving, she lost her driving licence. Yet as a brave Ukrainian she won her battle, being sober for more than 6 years.",
          area: "Kherson",
          cta: "Dogsana",
          url: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/33285512333342024108283031194714801062523424925006989749410649450989408485377",
        },
        {
          id: "doga-kurylenko",
          img: "nft-doga-kurylenko.jpg",
          title: "Doga Kurylenko",
          description:
            "Born and raised in Ukraine, she moved to Paris after success in modelling at the age of 16. Her acting career followed later - Hitman, Black Widow, Oblivion to name a few. But fans must agree she was absolutely unforgettable as a Bond girl in Quantum Of Solace, simply - this Olga has it!",
          area: "Sumy",
          cta: "Doga",
          url: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/33285512333342024108283031194714801062523424925006989749410649449889896857601",
        },
        {
          id: "andriy-shepperdchenko",
          img: "nft-andriy-shepperdchenko.jpg",
          title: "Andriy Shepperdchenko",
          description:
            "Football legend, striker for Dynamo Kyiv, AC Milan, and Chelsea, as well as the Ukrainian national team. After his career in sport he was about to kick into the politics. He stood for election in the Ukrainian Parliament in 2012, but his party failed to win for representation. This loss took his politics a bit “offside”.",
          area: "Kharkiv",
          cta: "Andriy",
          url: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/33285512333342024108283031194714801062523424925006989749410649446591361974273",
        },
        {
          id: "pug-borisovich-kum",
          img: "nft-pug-borisovich-kum.jpg",
          title: "Pug Borisovich Kum",
          description:
            "Moved to U.S. at the age of 16. Started with computer programming. With 9 years at Yahoo he found a gap on the market, and his WhatsApp paved the way for many. Made a fortune by selling the app to Facebook, where he resided as a member of the board, then made a fortune on Facebook stocks too. Nr. 65 in Forbes. Well known for his charitable contributions, this man is not afraid of giving - cumulatively around 1,5 billion dollars!",
          area: "Bucha",
          cta: "Pug",
          url: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/33285512333342024108283031194714801062523424925006989749410649452088920113153",
        },
      ],
    }
  },
  methods: {
    getImgSrc(img) {
      return require(`../../assets/ukraine/${img}`)
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../themes/variables.less";

.nft {
  aspect-ratio: 1 / 1;
  max-width: 2000px;
  max-height: 2000px;
  width: auto;
  height: auto;
}
</style>
