// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/clouds.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "section.hero[data-v-b66ee494]{position:relative;background:#ffe2d1 url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") repeat 0 0;-webkit-animation:animateClouds-data-v-b66ee494 28s linear 0s infinite normal none;animation:animateClouds-data-v-b66ee494 28s linear 0s infinite normal none;overflow:hidden;margin-bottom:-1px}@media (min-width:768px){.base-container[data-v-b66ee494]{padding-bottom:200px}}@media (min-width:1024px){.base-container[data-v-b66ee494]{padding-bottom:260px}}.grass-wrap[data-v-b66ee494]{position:relative}.grass-wrap .zorro-wrap[data-v-b66ee494]{width:100%;height:auto}@media (min-width:768px){.grass-wrap .zorro-wrap[data-v-b66ee494]{position:absolute;width:50%;bottom:calc(-40px + 5vw);right:0}}@media (min-width:1024px){.grass-wrap .zorro-wrap[data-v-b66ee494]{position:absolute;width:50%;bottom:calc(-40px + 5vw);right:0}}.grass-wrap .zorro-wrap .zorro[data-v-b66ee494]{padding-top:50px;position:relative;width:100%;z-index:40}.grass-wrap .grass-top[data-v-b66ee494]{position:absolute;bottom:-30px;left:-40px;width:300vw;z-index:30}@media (min-width:768px){.grass-wrap .grass-top[data-v-b66ee494]{width:130vw;left:0;bottom:30px}}@media (min-width:1024px){.grass-wrap .grass-top[data-v-b66ee494]{bottom:90px}}.grass-wrap .grass-color[data-v-b66ee494]{background-color:#97d26f;height:20px;width:100vw}@media (min-width:768px){.grass-wrap .grass-color[data-v-b66ee494]{height:60px}}@media (min-width:1024px){.grass-wrap .grass-color[data-v-b66ee494]{height:100px}}@-webkit-keyframes animateClouds-data-v-b66ee494{0%{background-position:0 0}to{background-position:-1450px 0}}@keyframes animateClouds-data-v-b66ee494{0%{background-position:0 0}to{background-position:-1450px 0}}", ""]);
// Exports
module.exports = exports;
