<template>
  <div class="pt-10 overflow-hidden">
    <Hero />

    <div class="relative z-20">
      <GrassBox>
        <h2 class="text-white md:pb-4">The Heroes Of Ukraine Collection</h2>
        <p class="pt-4 text-xl leading-normal max-w-4xl m-auto">
          In light of the recent war in Ukraine, we made a decision to test ourselves and NFT scene, creating the profitable NFT named  "Heroes of Ukraine", of which 100% of the proceeds are going to be sent directly to the Ministry of Health of Ukraine (public ETH address).
        </p>
        <img
          class="m-auto mt-10"
          src="~@/assets/ukraine/ukraine-all-eth.svg?raw"
        />

        <div class="mt-10 max-w-4xl m-auto">
          <BaseButton
            class="bg-cdlgrass-dark"
            @click="showHowItWorks = true"
            event="ukraine_how_it_works_click"
            v-if="!showHowItWorks"
            >How it works?</BaseButton
          >
          <div class="mt-10" v-if="showHowItWorks == true">
            <h3 class="text-cdlblack">Fractional NFT</h3>
            <p>
              Buy at any time and own a piece of peace. Each day, if value rises
              for more than 1 ETH, we will forward 100% to the official Ministry
              of Health of Ukraine ETH address and ask them to distribute the
              funds to hospitals in 10 major cities.<br /><br />
            </p>

            <h3 class="text-cdlblack">Auctioned NFTs</h3>
            <p>
              Auction within 10 days after launch (TBA). After auctions end, we
              will forward 100% to the official Ministry of Health of Ukraine
              ETH address and ask them to distribute the funds to hospitals in
              10 major cities.
            </p>
          </div>
        </div>
      </GrassBox>

      <Gallery />

      <!-- <Audiences /> -->
      <!-- <WhoWeHelp />
      <HowWeHelp /> -->
      <!-- <OurMission /> -->
      <!-- <JoinCuddle/> -->
      <Footer />
    </div>
  </div>
</template>

<script>
import mixinInitLoader from "@/mixins/initLoader"
import Menu from "@/components/common/Menu"
import Hero from "@/components/Ukraine/Hero"
import Gallery from "@/components/Ukraine/Gallery"
import GrassBox from "@/components/common/GrassBox"
import Audiences from "@/components/Landing/Audiences"
import WhoWeHelp from "@/components/Landing/WhoWeHelp"
import HowWeHelp from "@/components/Landing/HowWeHelp"
import OurMission from "@/components/Landing/OurMission"
import JoinCuddle from "../components/Landing/JoinCuddle"
import Footer from "@/components/common/Footer"

export default {
  name: "Ukraine",
  mixins: [mixinInitLoader],
  components: {
    Menu,
    Hero,
    GrassBox,
    Audiences,
    Gallery,
    WhoWeHelp,
    HowWeHelp,
    OurMission,
    JoinCuddle,
    Footer,
  },
  data() {
    return {
      showHowItWorks: false,
    }
  },
}
</script>
