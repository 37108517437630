<template>
  <section class="hero cont" v-view="onAppearInViewport">
    <BaseContainer class="base-container relative z-30 lg:pb-60 xl:pb-80">
      <HeroIntro
        :title="hero.title"
        :subtitle="hero.subtitle"
        :cta="hero.cta"
        class=' md:w-4/6'
      />
    </BaseContainer>

    <div class="grass-wrap">
      <BaseContainer class="relative">
        <div class="zorro-wrap">
          <img class="zorro" src="~@/assets/zorro-ukraine.svg?raw" />
        </div>
      </BaseContainer>
      <GrassTop class="grass-top" />
      <div class="grass-color" />
    </div>
  </section>
</template>

<script>
import mixinInitLoader from "@/mixins/initLoader"
import CuddleLogo from "@/assets/cuddle_logo_color.svg?inline"
import GrassTop from "@/assets/grass-top.svg?inline"
import ImageDogBowlPink from "@/assets/images/svg/raw/dog-bowl-pink.svg?raw"
import HeroIntro from "@/components/common/HeroIntro"

export default {
  name: "Hero",
  mixins: [mixinInitLoader],
  components: {
    CuddleLogo,
    GrassTop,
    ImageDogBowlPink,
    HeroIntro,
  },
  data() {
    return {
      showZorro: false,
      hero: {
        title: "Heroes of&nbsp;Ukraine",
        subtitle:
          "Our case study and testing ground for the upcoming NFT projects in the plan.",
        cta: {
          // to: 'ukraine',
          url: "#heroes",
          label: "Support your Hero",
          ukraine: true,
          cta: 'ukraine_support_your_hero_click'
        },
      },
    }
  },
  computed: {
    imgDogBowlPink() {
      return ImageDogBowlPink
    },
  },
  mounted() {},
  methods: {
    onAppearInViewport(e) {
      if (e.type == "enter") this.showZorro = true
      if (e.type == "exit") this.showZorro = false
    },
  },
}
</script>

<style lang="less" scoped>
@import "../../themes/variables.less";

section.hero {
  position: relative;
  // padding-top: 90px;
  background: theme("colors.cdlpeach.DEFAULT")
    url("../../assets/images/clouds.png") repeat 0 0;
  animation: 28s linear 0s normal none infinite animateClouds;
  overflow: hidden;
  margin-bottom: -1px;
}

.base-container {
  // padding-bottom: 580px;

  @media (min-width: @bp-md) {
    padding-bottom: 200px;
  }

  @media (min-width: @bp-lg) {
    padding-bottom: 260px;
  }
}

.grass-wrap {
  position: relative;

  .zorro-wrap {
    // position: absolute;
    // left: 0;
    // right: 0;
    // margin: 0 auto;
    // bottom: calc(-20px + 5vw);
    // width: 375px;
    // width: 100vw;
    width: 100%;
    height: auto;
    // outline: 1px #ff0000 solid;

    // @media (min-width: @bp-md) {
    //   width: 50%;
    //   bottom: calc(-40px + 5vw);
    //   margin-right: 10px;
    // }

     @media (min-width: @bp-md) {
      position: absolute;
      width: 50%;
      bottom: calc(-40px + 5vw);
      right: 0;
    }

    @media (min-width: @bp-lg) {
      position: absolute;
      width: 50%;
      bottom: calc(-40px + 5vw);
      right: 0;
    }

    .zorro {
      padding-top: 50px;
      position: relative;
      width: 100%;
      z-index: 40;
    }
  }
  .grass-top {
    position: absolute;
    bottom: -30px;
    left: -40px;
    width: 300vw;
    z-index: 30;

    @media (min-width: @bp-md) {
      width: 130vw;
      left: 0;
      bottom: 30px;
    }

    @media (min-width: @bp-lg) {
      bottom: 90px;
    }
  }
  .grass-color {
    background-color: theme("colors.cdlgrass.DEFAULT");
    height: 20px;
    width: 100vw;

    @media (min-width: @bp-md) {
      height: 60px;
    }

    @media (min-width: @bp-lg) {
      height: 100px;
    }
  }
}

@keyframes animateClouds {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -1450px 0;
  }
}
</style>
